<template>
  <div id="app">
    <div class="wrapper">
      <!-- <div class="tilted-background" /> -->
      <div style="background-color: rgb(248, 247, 242)">
        <b-img ref="logo" :src="require('@/assets/logo.png')" fluid class="mx-auto d-block" alt="Responsive image" />
      </div>
      <b-navbar toggleable="md" sticky class="navbar">
        <b-navbar-toggle target="navbar-toggle-collapse" />

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav align="center" class="mx-auto">
            <b-nav-item class="nav-item" @click="scrollToSection('about')"> O mnie </b-nav-item>
            <b-nav-item class="nav-item" @click="scrollToSection('oferta')"> Oferta </b-nav-item>
            <b-nav-item class="nav-item" @click="scrollToSection('porady')"> Porady </b-nav-item>
            <b-nav-item class="nav-item" @click="scrollToSection('kontakt')"> Kontakt </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <About ref="about" />
      <Oferta />
      <Pytania />
      <Pictures />
      <Kontakt />
      <!-- <InstagramPosts /> -->
      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "./sections/Footer.vue";
import About from "./sections/About.vue";
import Kontakt from "./sections/Kontakt.vue";
import Pytania from "./sections/Pytania.vue";
import Pictures from "./sections/Pictures.vue";
import Oferta from "./sections/Oferta.vue";
// import InstagramPosts from "./sections/InstagramPosts.vue";

export default {
  name: "App",
  components: { Footer, Kontakt, About, Pytania, Oferta, Pictures },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
body {
  /* background: rgb(240, 200, 210);
  background: linear-gradient(
    166deg,
    rgba(240, 200, 210, 0.9837184873949579) 14%,
    rgba(255, 255, 255, 1) 100%
  ); */
  font-family: "Lato", sans-serif;
  overflow: hidden;
  font-size: 1.1rem;
}
#app {
  width: 100vw;
  overflow: hidden;
}
/* .tilted-background {
  position: absolute;
  top: 0;
  left: -30%;
  width: 100%;
  height: 500vh;
  background-color: rgba(210, 62, 88, 0.9);
  clip-path: polygon(20% 0, 100% 0, 0% 100%, 0% 100%);
  z-index: 0;
} */
.wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
}

.bg-light-b {
  background-color: #eeedeb;
}
.nav-item {
  font-size: 20px;
}
.nav-link {
  color: #212529 !important;
}
.nav-link:hover {
  color: #6d2932 !important;
}
.btn-primary {
  color: #f9f8f3 !important;
  background-color: #134b70 !important;
  border-color: #134b70 !important;
}
.text-primary {
  color: #134b70 !important;
}
.primary {
  color: #134b70;
}
.btn-secondary {
  color: #212529 !important;
  background-color: #f9f8f3 !important;
  border-color: #f9f8f3 !important;
}
.secondary {
  color: #212529 !important;
  background-color: #f9f8f3 !important;
  border-color: #f9f8f3 !important;
}
.navbar {
  background-color: rgb(255, 255, 255, 0.7) !important;
}
.primary-outline {
  color: #212529 !important;
  background-color: #f9f8f3 !important;
  border-color: #f9f8f3 !important;
}
.primary-outline:hover,
.primary-outline:focus,
.primary-outline:active {
  color: #f9f8f3 !important;
  background-color: #134b70 !important;
  border-color: #134b70 !important;
  outline: none !important;
}
.social-icon {
  color: #424040;
}
.social-icon:hover {
  color: #134b70;
}
</style>
