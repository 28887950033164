<template>
  <b-container id="kontakt" class="px-3 px-lg-5 pb-5" fluid>
    <b-row>
      <b-col class="text-center">
        <span class="section-title"> Kontakt </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" lg="4" class="mb-2">
        <b-card style="height: 100%" class="shadow flex-fill">
          <l-map style="height: 300px" :zoom="zoom" :center="center">
            <l-tile-layer :url="url" />
            <l-marker :lat-lng="markerLatLng" />
          </l-map>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4" class="mb-2">
        <b-card style="height: 100%" class="shadow flex-fill">
          <b-row>
            <b-col cols="12" class="mb-4">
              <h4 class="my-auto text-center">
                <b-icon icon="pin-map-fill" class="w-100 my-2" />
              </h4>
              <h4 class="my-auto text-center">Kamienica 54C</h4>
            </b-col>
            <b-col cols="12" class="mb-4">
              <h4 class="my-auto text-center">
                <b-icon icon="phone-fill" class="w-100 my-2" />
              </h4>
              <h4 class="my-auto text-center">+48 531 537 733</h4>
            </b-col>
            <b-col cols="12" class="mb-2">
              <h4 class="my-auto text-center">
                <b-icon icon="envelope-fill" class="w-100 my-2" />
              </h4>
              <h4 class="my-auto text-center">dh.dietetyk@gmail.com</h4>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12" lg="4" class="mb-2">
        <b-card style="height: 100%" class="shadow flex-fill">
          <b-overlay :show="isFetching">
            <b-form id="contactForm">
              <b-form-group class="mb-3">
                <b-form-input v-model="form.name" required placeholder="Imię i nazwisko" />
              </b-form-group>
              <b-form-group class="mb-3">
                <b-form-input v-model="form.email" type="email" placeholder="E-mail" />
              </b-form-group>

              <b-form-group class="mb-3">
                <b-form-input v-model="form.phone" placeholder="Numer telefonu" type="number" />
              </b-form-group>
              <b-form-group class="mb-3">
                <b-form-textarea v-model="form.message" placeholder="Wiadomość" />
              </b-form-group>
              <b-button variant="primary" block :disabled="$v.form.$invalid" @click="sendEmail"> Wyslij </b-button>
            </b-form>
          </b-overlay>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { LMap, LTileLayer, LMarker } from "vue2-leaflet";
import emailjs from "emailjs-com";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
export default {
  name: "Kontakt",
  components: { LMap, LTileLayer, LMarker },
  mixins: [validationMixin],
  data() {
    return {
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 15,
      center: [49.574348, 20.346037],
      markerLatLng: [49.574348, 20.346037],
      form: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      isFetching: false,
    };
  },
  methods: {
    async sendEmail() {
      this.isFetching = true;
      try {
        const resp = await emailjs.send(
          "service_trqaibl",
          "template_wf5k2wo",
          {
            name: this.form.name,
            email: this.form.email,
            phone: this.form.phone,
            message: this.form.message,
          },
          "uH2SOAu9v3TsOw6-x"
        );
        if (resp.status === 200) {
          this.$bvToast.toast(`Twoja wiadomość została wysłana.`, {
            title: "Wiadomość wysłana",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isFetching = false;
        this.form = {
          name: "",
          email: "",
          phone: "",
          message: "",
        };
      }
    },
  },
  validations() {
    return {
      form: {
        name: { required },
        email: { required, email },
        phone: { required },
        message: { required },
      },
    };
  },
};
</script>
