<template>
  <b-container id="oferta" fluid class="mt-3">
    <b-row>
      <b-col class="text-center">
        <span class="section-title"> Oferta </span>
      </b-col>
    </b-row>
    <b-row align-h="center">
      <OfferCard title="Pierwsza Wizyta" price="150" icon="door-open">
        <template #content>
          <ul class="my-auto">
            <li>Analiza składu ciała.</li>
            <li>Analiza wyników badań laboratoryjnych.</li>
            <li>
              Analiza i omówienie dotychczasowego sposobu odżywiania i stylu życia na podstawie dzienniczka
              żywieniowego.
            </li>
            <li>Podsumowanie wizyty.</li>
            <li>Zalecenia żywieniowe i suplementacyjne.</li>
            <li>Ustalenie planu ewentualnej współpracy.</li>
          </ul>
        </template>
      </OfferCard>
      <OfferCard title="Zdrowy Start" icon="journal-plus" price="250">
        <template #content>
          <ul>
            <li>Analiza składu ciała.</li>
            <li>Analiza wyników badań laboratoryjnych.</li>
            <li>Szczegółowy wywiad kliniczno-żywieniowy.</li>
            <li>Analiza i omówienie dotychczasowego sposobu odżywiania na podstawie dzienniczka żywieniowego.</li>
            <li>
              Spersonalizowany jadłospis na 7 dni do kontynuacji przez 4 tygodnie opracowany z listą zakupów i
              zamienników.
            </li>
            <li>Dzienniczek postępów.</li>
            <li>Zalecenia żywieniowe i suplementacyjne.</li>
          </ul>
        </template>
      </OfferCard>
      <OfferCard title="Krok ku Witalności" icon="journal-plus" price="300" savings="50">
        <template #content>
          Pakiet Zdrowy Start oraz:
          <ul>
            <li>Miesięczna opieka dietetyczna poprzez kontakt telefoniczny lub mailowy.</li>
            <li>Podsumowująca konsultacja kontrolna z analizą składu ciała po 4 tygodniach.</li>
          </ul>
        </template>
      </OfferCard>

      <OfferCard title="Witalna Rewolucja" icon="journal-plus" price="650" savings="250">
        <template #content>
          Pakiet Zdrowy Start oraz:
          <ul>
            <li>Trzymiesięczna opieka dietetyczna.</li>
            <li>Trzy 7-dniowe jadłospisy, każdy do kontynuacji przez okres 4 tygodni.</li>
            <li>3 konsultacje kontrolne z analizą składu ciała.</li>
          </ul>
        </template>
      </OfferCard>
      <OfferCard title="Dietetyczna Harmonia" icon="journal-plus" price="350">
        <template #content>
          Dla osób, które nie potrzebują spersonalizowanego jadłospisu. Pakiet obejmuje 4 sesje psychodietetyczne.
          <ul>
            <li>
              Pomoc w budowaniu zdrowej relacji z jedzeniem co umożliwi osiągnięcie zamierzonych celów w sposób
              zrównoważony i trwały.
            </li>
            <li>Indywidualne zalecenia żywieniowe i suplementacyjne.</li>
          </ul>
        </template>
      </OfferCard>
      <OfferCard title="Konsultacja kontrolna" icon="journal-check" price="100">
        <template #content>
          Dla osób, które już korzystały z usług i chcą po jakimś czasie skontrolować ponownie swój stan odżywienia.
          <ul>
            <li>Analiza składu ciała.</li>
            <li>Analiza efektów. Omówienie trudności/ przeszkód/ postępów.</li>
            <li>Analiza nowych wyników badań laboratoryjnych.</li>
            <li>Dalsze rekomendacje żywieniowe oraz suplementacyjne.</li>
          </ul>
        </template>
      </OfferCard>
    </b-row>
  </b-container>
</template>
<script>
import OfferCard from "../components/OfferCard.vue";
export default {
  name: "Oferta",
  components: {
    OfferCard,
  },
  data() {
    return {};
  },
};
</script>
