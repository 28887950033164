<template>
  <div id="about" class="pt-5 pt-lg-0">
    <div class="tilted-background" />
    <b-row class="justify-content-end">
      <b-col class="py-auto pb-5 pb-lg-0 my-auto" cols="12" lg="6" xl="5">
        <div class="py-auto mx-4 mb-5 text-white">
          <span class="display-4 d-block text-center name-slide-in great-vibes-regular">
            Twoje zdrowie, moja misja - razem osiągniemy sukces!
          </span>
          <hr>

          <div class="text-slide-in">
            <p class="about-content text-center">
              Nazywam sie Anna Kusyk. Jestem magistrem dietetyki ze specjalizacją z psychodietetyki, absolwentką
              Śląskiego Uniwersytetu Medycznego, ukończyłam także studia podyplomowe na kierunku dietetyka pediatryczna.
            </p>
            <hr>
            <p class="about-content text-center mb-0">
              Jestem członkiem Polskiego Towarzystwa Dietetyki. Stale poszerzam swoje kompetencje poprzez udział w
              licznych szkoleniach oraz konferencjach.
            </p>
            <hr>
            <p class="about-content text-center mb-0">
              Specjalizuje się w <strong>dietetyce klinicznej</strong>, <strong>dietetyce pediatrycznej </strong> oraz
              <strong>psychodietetyce</strong>.
            </p>
            <b-row>
              <b-col class="text-center">
                <b-button size="lg" class="mt-3 mx-auto" @click="scrollToSection('kontakt')">
                  Kontakt
                </b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col cols="12" lg="6">
        <b-img
          :src="require('@/assets/011-nobg.png')"
          fluid
          style="min-width: 420px"
          class="mx-auto d-block pt-5"
          alt="Responsive image"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
.great-vibes-regular {
  font-family: "Great Vibes", cursive;
  font-weight: 400;
  font-style: normal;
}
#about {
  position: relative;
  background-color: rgb(249, 248, 243);
  overflow: hidden;
}

.tilted-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(19, 75, 112, 1);
  z-index: 0;
}

@media (min-width: 992px) {
  .tilted-background {
    clip-path: polygon(0 0, 90% 0, 50% 100%, 0% 100%);
    left: -15%;
  }
}

#about .b-row {
  position: relative;
  z-index: 1;
}

.about-content {
  font-size: 1.1rem;
}

.name-slide-in {
  animation: nameSlideInFromLeft 1s ease-out forwards;
}

@keyframes nameSlideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.text-slide-in {
  animation: textSlideInFromLeft 2s ease-out forwards;
}

@keyframes textSlideInFromLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  50% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>
