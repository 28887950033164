<template>
  <b-container fluid class="pt-4 px-0 pb-0 m">
    <!-- <div class="parallax">
      <b-row class="p-5 justify-content-center">
        <b-col class="my-5" cols="12" lg="6">
          <b-overlay :show="isFetching">
            <b-card>
              <p class="text-center">
                Więcej zdrowia w Twojej skrzynce odbiorczej! Subskrybuj mój newsletter dietetyczny już teraz i otrzymuj
                cenne wskazówki oraz pyszne przepisy prosto do Twojej skrzynki mailowej!
              </p>
              <b-input v-model="email" placeholder="Email" type="email" class="mb-2" />
              <b-button variant="primary" block :disabled="$v.email.$invalid" @click="sendEmail"> Zapisz się </b-button>
            </b-card>
          </b-overlay>
        </b-col>
      </b-row>
    </div> -->

    <div class="mb-3 pb-3">
      <b-row class="text-center py-5">
        <b-col class="">
          <b-button variant="text" class="p-3 m-2" href="https://www.facebook.com/profile.php?id=61555817423364" blank>
            <h3 class="my-auto social-icon">
              <b-icon icon="facebook" />
            </h3>
          </b-button>
          <b-button href="https://www.instagram.com/dietetycznaharmonia/" variant="text" class="p-3 m-2">
            <h3 class="my-auto social-icon">
              <b-icon icon="instagram" />
            </h3>
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>
<script>
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import emailjs from "emailjs-com";
export default {
  name: "Footer",
  mixins: [validationMixin],
  data() {
    return {
      isFetching: false,
      email: null,
    };
  },
  methods: {
    async sendEmail() {
      this.isFetching = true;
      try {
        const resp = await emailjs.send(
          "service_trqaibl",
          "template_wf5k2wo",
          {
            email: this.email,
          },
          "uH2SOAu9v3TsOw6-x"
        );
        if (resp.status === 200) {
          this.$bvToast.toast(`Pomyślnie zapisano do newslettera.`, {
            title: "Newsletter zapisany",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "success",
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isFetching = false;
        this.email = "";
      }
    },
  },
  validations() {
    return {
      email: { required, email },
    };
  },
};
</script>
<style scoped>
body,
html {
  height: 100%;
}

.parallax {
  /* The image used */
  background-image: url("./../assets/fruit2.jpg");

  /* Full height */
  height: 100%;

  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
