<template>
  <b-container id="porady" class="mt-3 px-0" fluid>
    <b-row>
      <b-col class="text-center">
        <span class="section-title"> Porady Przedwizytowe</span>
      </b-col>
    </b-row>
    <b-row id="porady-inner" class="py-5" align-h="center">
      <b-col cols="11" xl="6">
        <b-carousel v-model="slide" interval="100000000" fade @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
          <b-carousel-slide img-blank>
            <b-card class="slide-card" title="Przygotowanie do pierwszej wizyty" body-class="text-left">
              <b-card-text>
                Aby jak najlepiej przygotować się do spotkania, proszę pamiętać o kilku istotnych kwestiach.
                <ul>
                  <li>Zarezerwuj 1 - 1,5 h na pierwszą wizytę.</li>
                  <li>
                    Zabierz ze sobą badania laboratoryjne. Te informacje pomogą w lepszym zrozumieniu Twojego stanu
                    zdrowia.
                  </li>
                  <li>Przygotuj dzienniczek żywieniowy - jego analiza przybliży mi Twój model żywienia.</li>
                  <li>
                    Przygotuj listę leków i suplementów diety iakie przyjmujesz wraz z dawkowaniem i marką preparatu.
                  </li>
                  <li>
                    Pamiętaj, że wizyta w gabinecie dietetycznym to partnerska współpraca, gdzie Twoja aktywna rola i
                    otwartość na współpracę są kluczowe.
                  </li>
                  <li>
                    Gotowość do dzielenia się informacjami oraz skupienie na celu pozwoli nam efektywnie pracować nad
                    Twoim zdrowiem i kondycją. Nie oceniam – pomagam.
                  </li>
                </ul>
              </b-card-text>
            </b-card>
          </b-carousel-slide>
          <!-- <b-carousel-slide img-blank>
                <b-card
                  class="slide-card"
                  body-class="text-left"
                  title="Przygotowanie do analizy składu ciałą urządzeniem IN BODY 120"
                >
                  <b-card-text>
                    Aby jak najlepiej przygotować się do spotkania, proszę pamiętać o kilku istotnych kwestiach. Badanie
                    wykonuje się:
                    <ul>
                      <li>Najlepiej na czczo lub min. 2 - 3 godziny po posiłku oraz nawodnieniu.</li>
                      <li>Przed wysiłkiem fizycznym.</li>
                      <li>Przed kąpielą lub prysznicem.</li>
                      <li>Po wypróżnieniu.</li>
                      <li>Bez biżuterii.</li>
                      <li>Boso, najlepiej w bieliźnie.</li>
                    </ul>
                    Przeciwwskazania:
                    <ul>
                      <li>Posiadanie rozrusznika serca.</li>
                      <li>Posiadanie urządzenia elektrycznego w jamie brzusznej typu “Ability”.</li>
                      <li>Ciąża.</li>
                      <li>Menstruacja.</li>
                      <li>
                        Padaczka: wymagana zgoda lekarska na przeprowadzenie badania metodą bioelektrycznej impedancji
                        (BIA).
                      </li>
                    </ul>
                  </b-card-text>
                </b-card>
              </b-carousel-slide> -->
          <b-carousel-slide img-blank>
            <b-card class="slide-card" body-class="text-left" title="Badania laboratoryjne">
              <b-card-text>
                Na wizytę proponuję zabrać ze sobą aktualne wyniki badań laboratoryjnych. <br>
                To pomoże mi lepiej zrozumieć Twój obecny stan zdrowia i dostosować plan żywieniowy do Twoich aktualnych
                potrzeb.

                <ul>
                  <li>Morfologia krwi z rozmazem.</li>
                  <li>CRP.</li>
                  <li>Próby wątrobowe (ALT, AST, GGTP, BIL).</li>
                  <li>Lipidogram (CHOL, TG, HDL, LDL).</li>
                  <li>Panel tarczycowy (TSH, FT3, FT4).</li>
                  <li>Kreatynina, kwas moczowy.</li>
                  <li>Glukoza i insulina.</li>
                  <li>Żelazo i ferrytyna.</li>
                  <li>Oznaczenie poziomu witaminy D.</li>
                </ul>
              </b-card-text>
            </b-card>
          </b-carousel-slide>
          <!-- <b-carousel-slide img-blank>
            <b-card class="slide-card" body-class="text-left" title="Badania krwi">
              <b-card-text>
                Przed wizytą zalecam dostarczenie aktualnych wyników badań laboratoryjnych (wykonane najlepiej do 2
                tygodni przed wizytą, nie starsze niż 3 miesiące). To pomoże mi lepiej zrozumieć Twój obecny stan
                zdrowia i dostosować plan żywieniowy do Twoich aktualnych potrzeb. W razie niepokojących wyników i
                objawów, zawsze sugeruję konsultację lekarską w celu dalszej oceny stanu zdrowia. Morfologia krwi z
                rozmazem.
                <ul>
                  <li>CRP.</li>
                  <li>Próby wątrobowe (ALT, AST, GGTP, BIL).</li>
                  <li>Lipidogram (CHOL, TG, HDL, LDL, nie-HDL).</li>
                  <li>Panel tarczycowy (TSH, FT3, FT4).</li>
                  <li>Kreatynina, eGFR, kwas moczowy.</li>
                  <li>Glukoza i insulina.</li>
                  <li>Żelazo i ferrytyna.</li>
                  <li>Stężenie witaminy D</li>
                </ul>
              </b-card-text>
            </b-card>
          </b-carousel-slide> -->
          <b-carousel-slide img-blank>
            <b-card class="slide-card" body-class="text-left" title="Przygotowanie do badań laboratoryjnych">
              <b-card-text>
                <ul>
                  <li>Zachowaj dotychczasową dietę.</li>
                  <li>Nie wykonuj badania w okresie miesiączki, po spożyciu alkoholu.</li>
                  <li>Bądź na czczo - ostatni posiłek spożyj najpóźniej 8 h przed pobraniem.</li>
                  <li>Rano przed badaniem nie wypijaj kawy/ herbaty, jedynie wodę niegazowaną.</li>
                  <li>Unikaj wysiłku fizycznego przez ok. 24 godziny przed pobraniem krwi.</li>
                </ul>
              </b-card-text>
            </b-card>
          </b-carousel-slide>
        </b-carousel>
        <b-row>
          <b-col class="text-center mt-3">
            <b-button :disabled="slide === 0" variant="secondary" class="mx-2" pill @click="leftClicked">
              <b-icon icon="arrow-left" />
            </b-button>
            <b-button class="mx-2" pill :disabled="slide === 3" variant="secondary" @click="rightClicked">
              <b-icon icon="arrow-right" />
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Pytania",
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
    leftClicked() {
      this.slide = this.slide !== 0 ? this.slide + -1 : 3;
    },
    rightClicked() {
      this.slide = this.slide !== 3 ? this.slide + 1 : 0;
    },
  },
};
</script>

<style>
#porady-inner {
  background-color: #134b70;
}
.slide-card {
  height: inherit !important;

}
.carousel-inner {
  border-radius: 0.5rem !important;
  height: 800px !important;
  font-size: 0.9rem;
}
@media (min-width: 576px) {
  .carousel-inner {
    height: 450px !important;
    font-size: 1rem;
  }
  .carousel-caption {
    height: 450px !important;
  }
}

.carousel-control-prev,
.carousel-control-next {
  width: 7% !important;
}
.carousel-caption {
  top: 0% !important;
  left: 0% !important;
  right: 0% !important;
  bottom: 0% !important;
  color: black !important;
  height: 800px !important;
  padding: 0 !important;
}
.slide {
  border-radius: 0.5rem !important;
  background: #c4c1b4 !important;
}
.parallax {
  background-image: url("./../assets/bginfo.jpg");
  height: 100%;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.cover {
  height: 100% !important;
  position: relative;
  padding: 0px 30px;
}
</style>
