<template>
  <b-col xs="11" sm="12" lg="9" class="mb-4">
    <b-card class="shadow offer-card" body-class="p-3 py-4">
      <b-row>
        <b-col class="my-auto" cols="12" md="4">
          <div v-if="savings" class="savings px-3 py-1 bg-success text-white w-100 text-center">
            <b-icon icon="currency-dollar" /> Oszczędzasz {{ savings }}zł
          </div>
          <b-card-title class="text-center h3 my-5">
            <!-- <div class="d-block mb-5">
              <b-iconstack font-scale="5">
                <b-icon stacked :icon="icon" style="color: #6d2932" />
              </b-iconstack>
            </div> -->
            {{ title }}
            <div class="text-muted text-center mt-3 mb-3 mb-md-0 h2">{{ price }}zł</div>
          </b-card-title>
        </b-col>
        <b-col class="border-left py-auto d-flex justify-content-center">
          <div class="my-auto w-100">
            <b-card-text class="acc-content">
              <slot name="content" />
            </b-card-text>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </b-col>
</template>
<script>
export default {
  name: "OfferCard",
  props: {
    title: {
      type: String,
      required: true,
    },
    price: {
      type: [Number, String],
      default: 0,
    },
    icon: {
      type: String,
      required: true,
    },
    savings: {
      type: [null, Number, String],
      default: null,
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
::v-deep .acc-content {
  font-size: 1.1rem;
}
.offer-card {
  position: relative;
}
.savings {
  /* position: absolute; */
  /* top: 10px; */
  /* left: 10px; */
  /* transform: rotate(-10deg); */
  border: 1px solid #28a745;
  border-radius: 0.5rem;
  font-size: 1.3rem;
}
</style>
